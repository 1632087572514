import ProjectsPage from "../../pages/Projects";
import Heading from "../Heading";

const ProjectSection = () => {
  return (
    <div className="bg-faded">
      <div className="pt-16 mx-auto">
        <Heading title={"Projects"} subtitle={"What i have done so far"} />
      </div>

      <ProjectsPage />
    </div>
  );
};

export default ProjectSection;
