import HeroSection from "../components/sections/HeroSection";
import AboutSection from "../components/sections/AboutSection";
import ProjectSection from "../components/sections/ProjectSection";
import FadedText from "../components/FadedText";
import SkillSection from "../components/sections/SkillSection";
import ServiceSection from "../components/sections/ServiceSection";
import ContactSection from "../components/sections/ContactSection";
import EducationSection from "../components/sections/EducationSection";

const HomePage = () => {
  return (
    <>
      <HeroSection />
      <FadedText text={"Enthusiast"} />
      <AboutSection />
      <SkillSection />
      <ServiceSection />
      <EducationSection />
      <ProjectSection />
      <ContactSection />
    </>
  );
};

export default HomePage;
