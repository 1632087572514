import { useState } from "react";
import { services } from "../components/ServicesData";

import { FaLongArrowAltRight, FaRegCheckCircle } from "react-icons/fa";
import { IoClose } from "react-icons/io5";

const ServicesPage = () => {
  const [toggleState, setToggleState] = useState(0);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  let classes =
    "fixed top-0 bottom-0 left-0 right-0 bg-blur z-[999] flex justify-center items-center px-4";

  let modalClasses = classes + " opacity-0 invisible";
  let activeClasses = classes + " opacity-100 visible";

  const renderServices = () => {
    return services.map((service, index) => (
      <div
        key={index}
        className="relative border-2 bg-white rounded-lg pt-16 md:pt-24 pl-6 md:pl-10 pr-28 md:pr-10 pb-4 md:pb-8"
      >
        {service.icon}
        <h3 className="md:text-lg font-medium mb-4">{service.title}</h3>
        <button
          className="flex items-center gap-x-2 text-sm hover:font-medium hover:text-primary text-lightGrey"
          onClick={() => toggleTab(service.id)}
        >
          View More
          <FaLongArrowAltRight className="hover:translate-x-2 duration-300" />
        </button>

        {/* Service Modal */}
        <div
          className={toggleState === service.id ? activeClasses : modalClasses}
        >
          <div className="w-[500px] relative bg-white pt-16 pr-10 pb-10 pl-10 rounded-2xl shadow-md">
            <IoClose
              className="absolute top-6 right-6 text-3xl cursor-pointer"
              onClick={() => toggleTab(0)}
            />
            <h3 className="text-center text-2xl font-medium mb-4">
              {service.title}
            </h3>
            <p className="text-center lg:text-sm mb-8">{service.description}</p>

            <ul>
              {service.features.map((feature, index) => (
                <li key={index} className="flex items-center gap-x-2 mb-4">
                  <FaRegCheckCircle className="font-medium text-lg" />
                  <p className="text-base">{feature}</p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    ));
  };

  return (
    <section className="lg:h-[95vh] md:h-[85vh] h-[95vh] flex items-center  bg-faded overflow-x-hidden px-6 lg:px-0">
      <div className="lg:w-[68%] mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 justify-center gap-x-8">
          {renderServices()}
        </div>
      </div>
    </section>
  );
};

export default ServicesPage;
