import React, { useEffect, useRef } from "react";
import Typed from "typed.js";

const RoleDisplay = () => {
  const typedRef = useRef(null);

  useEffect(() => {
    const roles = {
      strings: [
        "Web Developer",
        "Graphics Designer",
        "Passionate Coder",
      ],
      typeSpeed: 80,
      backSpeed: 60,
      backDelay: 1000,
      loop: true,
    };

    const typed = new Typed(typedRef.current, roles);

    return () => {
      typed.destroy();
    };
  }, []);
  return <span ref={typedRef}></span>;
};

export default RoleDisplay;
