import Education from "../../pages/Education";
import Heading from "../Heading";

const EducationSection = () => {
  return (
    <div>
      <div className="pt-16 mx-auto">
        <Heading title={"Qualification"} subtitle={"An academic journey"} />
      </div>
      <Education />
    </div>
  );
};

export default EducationSection;
