import { Link } from "react-router-dom";

import { FaPaperPlane, FaArrowDown } from "react-icons/fa";
import { CgMouse } from "react-icons/cg";

import RoleDisplay from "../RoleDisplay";
import heroImage from "../../assets/heroImg.png";
import Button from "../Button";

const HeroSection = () => {
  return (
    // outer div
    <div className="relative lg:h-[98vh] overflow-x-hidden flex justify-center items-center lg:bottom-6">
      {/* inner div*/}
      <div className="flex flex-col lg:flex-row justify-between items-center lg:w-[68%] mx-auto mt-20 lg:mt-0 py-12 lg:py-2 gap-20">
        {/* left side */}
        <div className="lg:w-[52%] w-[85%] flex flex-col lg:text-start text-center gap-4">
          <div className="text-2xl">Hi there, I'm</div>
          <div className="lg:text-4xl text-3xl font-semibold">
            Ronak Vaghasiya &#128075;
          </div>
          <div className="flex lg:justify-start lg:items-start justify-center gap-6 text-xl">
            <div className="w-32 bg-primary h-[2.5px] my-4 rounded mt-3 hidden lg:block" />
            <RoleDisplay/>
          </div>
          {/* subheading */}
          <div className=" text-sm lg:text-base" >
            I'm a developer based in India. I'm passionate about becoming a
            full-stack developer and love creating new things.
          </div>

          {/* buttons */}
          <div className="flex items-center lg:justify-start justify-center lg:gap-8 gap-6 mt-6">
            <div>
              <Link to={"/contact"}>
                <Button text={"Hire Me"} icon={<FaPaperPlane />} />
              </Link>
            </div>

            {/* scroll down */}
            <div className="flex justify-center items-center gap-2">
              <CgMouse className="text-2xl animate-bounce transform translate-x-1/2" />
              <p>Scroll Down</p>
              <FaArrowDown />
            </div>
          </div>
        </div>

        {/* right side */}
        <div>
          <img src={heroImage} className="lg:h-[400px] h-[350px] lg:w-[400px] w-[350px]" alt="" />
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
