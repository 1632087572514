const Button = ({ text, icon }) => {
  return (
    <button
      className="flex justify-center items-center gap-2 bg-primary 
    text-white border-2 border-primary py-2 px-4 rounded-full 
    font-medium hover:bg-white hover:text-primary"
    >
      <div>{text}</div> 
      <div>{icon}</div>
    </button>
  );
};

export default Button;
