import project1 from "../assets/ProjectsImg/project1.png";
import project2 from "../assets/ProjectsImg/project2.png";
import project3 from "../assets/ProjectsImg/project3.png";
import project4 from "../assets/ProjectsImg/project4.png";
import project5 from "../assets/ProjectsImg/project5.png";
import project6 from "../assets/ProjectsImg/project6.png";

export const projectData = [
  {
    id: 1,
    image: project1,
    title: "Job Portal",
    url: "https://yourjobportal.netlify.app/",
    category: "web",
  },
  {
    id: 2,
    image: project2,
    title: "Food Order App",
    url: "https://yourreactfood.netlify.app/",
    category: "web",
  },
  {
    id: 3,
    image: project3,
    title: "Investment Calculator",
    url: "https://yourinvestment.netlify.app/",
    category: "web",
  },
  {
    id: 4,
    image: project4,
    title: "Place Picker",
    url: "https://yourplacepicker.netlify.app/",
    category: "web",
  },
  {
    id: 5,
    image: project5,
    title: "Razorpay Website Clone",
    url: "https://yourrazorpayclone.netlify.app/",
    category: "design",
  },
  {
    id: 6,
    image: project6,
    title: "Password Generator",
    url: "https://yourpasswordhelper.netlify.app/",
    category: "design",
  },
];

export const projectsNav = [
  {
    title: "all",
  },
  {
    title: "web",
  },
  {
    title: "design",
  },
];
