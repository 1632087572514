import ServicesPage from "../../pages/Services";
import Heading from "../Heading";

const ServiceSection = () => {
  return (
    <div className='bg-faded'>
      <div className='pt-16 mx-auto'>
        <Heading title={"Services"} subtitle={"What i do"} />
      </div>
      <ServicesPage />
    </div>
  );
};

export default ServiceSection;
