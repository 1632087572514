import {
  FaRegCopyright,
  FaSquareInstagram,
  FaSquareBehance,
  FaSquareDribbble,
} from "react-icons/fa6";

import { NavLink } from "react-router-dom";
import Rlogo from "../assets/R.png";

const Footer = () => {
  const navLinks = [
    { name: "Home", path: "/" },
    { name: "About", path: "/about" },
    { name: "Projects", path: "/projects" },
    { name: "Services", path: "/services" },
    { name: "Contact", path: "/contact" },
  ];

  return (
    <div className="flex items-center justify-center h-52 lg:h-40 w-full overflow-x-hidden border-t-2 border-brdr px-6">
      <div className="grid lg:grid-cols-4.1 justify-center h-30 w-[1120px]">
        <div className="flex flex-col lg:flex-row justify-start items-center lg:gap-16">
          <img
            src={Rlogo}
            alt="RONAK's PORTFOLIO"
            width="250vh"
            height="250vh"
          />
          <span className="hidden lg:block w-[2px] bg-primary h-[80px] rounded-full"></span>
          <span className="lg:hidden w-[40vh] md:w-[50vh] bg-brdr h-[2px] mt-8 mb-6 rounded-full"></span>
        </div>

        {/* links */}
        <div className="flex flex-col justify-center gap-y-2 lg:gap-y-1 items-center lg:items-start lg:ml-6">
          <div>
            <ul className="flex gap-x-4">
              {navLinks.map((link) => (
                <li key={link.name}>
                  <NavLink
                    to={link.path}
                    className="font-medium hover:font-semibold"
                  >
                    {link.name}
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>
          <div className="flex items-center gap-x-1 text-sm select-none">
            <FaRegCopyright />
            2024 Ronak Vaghasiya, All Rights Reserved.
          </div>
        </div>
        <div></div>
        <div className="lg:flex hidden flex-col justify-center items-start gap-y-2">
          <div className="flex gap-x-4 text-2xl">
            <a
              href="https://www.instagram.com/_ronak_vaghasiya/"
              target="_blank"
              rel="noreferrer"
            >
              <FaSquareInstagram />
            </a>
            <a
              href="https://www.behance.net/ronakvaghasiya/"
              target="_blank"
              rel="noreferrer"
            >
              <FaSquareBehance />
            </a>
            <a
              href="https://dribbble.com/ronak-vaghasiya/"
              target="_blank"
              rel="noreferrer"
            >
              <FaSquareDribbble />
            </a>
          </div>
          <span className="flex text-sm gap-x-1">
            Support:
            <a
              href="mailto:vaghasiyaronak5968@gmail.com"
              target="_blank"
              rel="noreferrer"
            >
              vaghasiyaronak5968@gmail.com
            </a>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
