import profile from "../assets/profilePhoto.jpg";
import { FcIdea, FcMultipleDevices, FcAssistant } from "react-icons/fc";
import { IoDocumentText } from "react-icons/io5";
import Card from "../components/Card";
import Button from "../components/Button";
import Resume from "../assets/RonakResume.pdf";

const AboutPage = () => {
  return (
    <section className="flex items-center bg-faded lg:h-[95vh] md:h-[120vh] h-[190vh] overflow-x-hidden">
      {/* inner div */}
      <div className="lg:w-[68%] mx-auto">
        <div className="flex h-full flex-col lg:flex-row items-center lg:gap-x-28 gap-y-24">
          {/* left */}
          <div>
            <img
              src={profile}
              alt="profile"
              className="lg:max-w-[320px] h-full max-w-[280px] rounded-xl animate-scaleImg p-6 grayscale transition-all"
              loading="lazy"
            />
          </div>

          {/* right */}
          <div className="flex flex-col lg:items-start lg:gap-10 gap-16 lg:w-[600px]">
            {/* card */}
            <div className="flex md:flex-row flex-col gap-x-4 mx-auto gap-y-6">
              {/* card 1 */}
              <Card
                icon={<FcIdea />}
                title={"Experience"}
                subTitle={"1 Year Working"}
              />

              {/* card 2 */}
              <Card
                icon={<FcMultipleDevices />}
                title={"Completed"}
                subTitle={"5+ Projects"}
              />

              {/* card 3 */}
              <Card
                icon={<FcAssistant />}
                title={"Support"}
                subTitle={"Online 24/7"}
              />
            </div>

            <div className="gap-4 flex flex-col lg:text-start text-center">
              <p>
                Frontend developer, with expertise in crafting responsive,
                user-friendly web interfaces. I specialize in HTML, CSS,
                JavaScript, and modern frameworks like React and Redux, creating
                seamless and engaging digital experiences.
              </p>
              <p>Let's collaborate to bring innovative ideas to life!</p>
            </div>

            <div className="flex items-center justify-center">
              <a href={Resume} target="_blank">
                <Button text={"Download CV"} icon={<IoDocumentText />} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutPage;
