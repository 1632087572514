import FrontendSkill from "../components/FrontendSkill";
import BackendSkill from "../components/BackendSkill";

const SkillsPage = () => {
  return (
    <section className="flex items-center lg:h-[95vh] md:h-[70vh] h-[100vh] overflow-x-hidden px-6 lg:px-0 ">
      <div className="lg:w-[68%] mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-y-4 md:gap-x-12 content-center">
          <FrontendSkill />
          <BackendSkill />
        </div>
      </div>
    </section>
  );
};

export default SkillsPage;
