import React from "react";

const FadedText = ({ text }) => {
  let classes =
    "absolute text-faded text-[150px] select-none font-medium hidden lg:block transition-all duration-500 -bottom-12 -left-8";
  
    return <div className={classes}>{text}</div>;
};

export default FadedText;
