import ContactPage from "../../pages/Contact";
import Heading from "../Heading";

const ContactSection = () => {
  return (
    <div>
      <div className="pt-16 mx-auto">
        <Heading title={"Get In Touch"} subtitle={"Let's connect"} />
      </div>

      <ContactPage />
    </div>
  );
};

export default ContactSection;
