import ProjectsItems from "../components/ProjectsItem";
import { projectData, projectsNav } from "../components/ProjectData";
import { useState, useEffect } from "react";

const ProjectsPage = () => {
  const [item, setItem] = useState({ title: "all" });
  const [projects, setProjects] = useState([]);
  const [active, setActive] = useState(0);

  let classes =
    "px-3 py-1 hover:bg-primary hover:text-white font-medium rounded-lg cursor-pointer capitalize";

  let activeClasses = "bg-primary text-white";

  useEffect(() => {
    if (item.title === "all") {
      setProjects(projectData);
    } else {
      setProjects(
        projectData.filter(
          (project) => project.category.toLowerCase() === item.title
        )
      );
    }
  }, [item]);

  const handleClick = (e, index) => {
    setItem({ title: e.target.textContent.toLowerCase() });
    setActive(index);
  };

  return (
    <section className="flex flex-col items-center lg:h-[115vh] bg-faded md:h-[150vh] h-[260vh] px-6 overflow-x-hidden lg:px-0">
      <div className="mt-28">
        {/* project navbar */}
        <div className="flex justify-center items-center mb-12 gap-x-6">
          {projectsNav.map((title, index) => {
            return (
              <span
                onClick={(e) => {
                  handleClick(e, index);
                }}
                key={index}
                className={`${
                  active === index ? activeClasses : ""
                } ${classes}`}
              >
                {title.title}
              </span>
            );
          })}
        </div>
      </div>

      <div className="mx-auto mt-4 lg:w-[68%]">
        {/* project cards */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 content-center gap-8">
          {projects.map((item) => {
            return <ProjectsItems item={item} key={item.id} />;
          })}
        </div>
      </div>
    </section>
  );
};

export default ProjectsPage;
