import { FaPaperPlane } from "react-icons/fa";
import Button from "./Button";
import React, { useState, useRef } from "react";
import emailjs from "@emailjs/browser";

const ContactForm = () => {
  const form = useRef();

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    country: "",
    phone: "",
    message: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleReset = () => {
    setFormData({
      first_name: "",
      last_name: "",
      email: "",
      country: "",
      phone: "",
      message: "",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let result = await fetch("https://portfolio-website-994b.onrender.com/", {
      method: "POST",
      body: JSON.stringify(formData),
      headers: {
        "Content-Type": "application/json",
      },
    });

    // form validation
    const newErrors = {};

    if (formData.first_name.trim() === "") {
      newErrors.first_name = "Please enter first name";
    }

    if (formData.last_name.trim() === "") {
      newErrors.last_name = "Please enter last name";
    }

    if (formData.email.trim() === "") {
      newErrors.email = "Please enter email address";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Invalid email address";
    }

    if (formData.phone.trim() === "") {
      newErrors.phone = "Please enter phone number";
    } else if (!/^\d{10}$/.test(formData.phone)) {
      newErrors.phone = "Invalid phone number";
    }
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {

      // storing inquiry data in database
      result = await result.json();
      localStorage.setItem("inquiries", JSON.stringify(result));

      // sending email using emailjs
      emailjs.sendForm("service_7s54xnu", "template_s4ueh2f", form.current, {
        publicKey: "CS-0Y-YEzrwB1Arh2",
      });

      alert("Your message has been sent successfully!");
    }
    handleReset();
  };

  return (
    <form className="flex flex-col gap-y-4" ref={form} onSubmit={handleSubmit}>
      {/* first row */}
      <div className="flex gap-2">
        <div>
          <input
            type="text"
            name="first_name"
            placeholder="First name"
            className={`w-full p-3 border-2 rounded-xl ${
              errors.first_name ? "border-red-500" : ""
            }`}
            value={formData.first_name}
            onChange={handleChange}
          />
          {errors.first_name && (
            <p className="text-red-500 text-xs">{errors.first_name}</p>
          )}
        </div>

        <div>
          <input
            type="text"
            name="last_name"
            placeholder="Last name"
            className={`w-full p-3 border-2 rounded-xl ${
              errors.last_name ? "border-red-500" : ""
            }`}
            value={formData.last_name}
            onChange={handleChange}
          />
          {errors.last_name && (
            <p className="text-red-500 text-xs">{errors.last_name}</p>
          )}
        </div>
      </div>

      {/* second row */}
      <div>
        <input
          type="email"
          name="email"
          placeholder="Email address"
          className={`w-full p-3 border-2 rounded-xl ${
            errors.email ? "border-red-500" : ""
          }`}
          value={formData.email}
          onChange={handleChange}
        />
        {errors.email && <p className="text-red-500 text-xs">{errors.email}</p>}
      </div>

      {/* third row */}
      <div className="flex gap-3">
        <select
          id="country"
          name="country"
          className={"p-3 border-2 rounded-xl"}
          value={formData.country}
          onChange={handleChange}
        >
          <option value="India">+91</option>
          <option value="Australia">+61</option>
          <option value="Germany">+49</option>
          <option value="New Zealand">+64</option>
          <option value="Singapore">+65</option>
          <option value="United Kingdom">+44</option>
          <option value="other">Other</option>
        </select>

        <div>
          <input
            type="phone"
            name="phone"
            placeholder="Phone number"
            className={`w-4/5 p-3 border-2 rounded-xl ${
              errors.phone ? "border-red-500" : ""
            }`}
            value={formData.phone}
            onChange={handleChange}
          />
          {errors.phone && (
            <p className="text-red-500 text-xs">{errors.phone}</p>
          )}
        </div>
      </div>

      {/* fourth row */}
      <div>
        <textarea
          name="message"
          placeholder="Write your project here..."
          className={"w-full p-3 border-2 rounded-xl h-36"}
          value={formData.message}
          onChange={handleChange}
        />
      </div>
      <div className="flex lg:justify-start justify-center">
        <Button text={"Send Message"} icon={<FaPaperPlane />} />
      </div>
    </form>
  );
};

export default ContactForm;