const Heading = ({title, subtitle}) => {
  return (
    <div className="flex flex-col justify-center items-center">
      <div className="lg:text-3xl text-2xl font-semibold">{title}</div>
      <div className="flex justify-center items-center gap-3">
        <div className="h-[2px] lg:w-5 w-3 bg-primary rounded-full" />
        <p className="lg:text-base text-sm">{subtitle}</p>
        <div className="h-[2px] lg:w-5 w-3 bg-primary rounded-full" />
      </div>
    </div>
  );
};

export default Heading;
