const Card = ({ icon, title, subTitle }) => {
  return (
    <div className="lg:h-32 lg:w-48 md:h-36 md:w-60 h-40 w-64 bg-white border-2 rounded-xl py-2 px-2 flex justify-center">
      <div className="flex flex-col justify-evenly items-center">
        <div className="text-2xl">
          {icon}
        </div>
        <h2 className="text-lg lg:text-base font-medium">{title}</h2>
        <p className=" text-lightGrey ">{subTitle}</p>
      </div>
    </div>
  );
};

export default Card;
