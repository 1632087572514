import SkillsPage from "../../pages/Skills";
import Heading from "../Heading";

const SkillSection = () => {
  return (
    <div>
      <div className="pt-16 mx-auto">
        <Heading title={"Skills"} subtitle={"What I know"} />
      </div>
      <SkillsPage />
    </div>
  );
};

export default SkillSection;
