const ErrorPage = () => {
  return (
    <div className="text-center mt-16">
      <h1 className="text-xl font-bold">404: Page Not Found</h1>
      <p>Sorry, the page you are looking for does not exist.</p>
    </div>
  );
};

export default ErrorPage;
