import { FaLongArrowAltRight } from "react-icons/fa";

const ProjectsItem = ({ item }) => {
  return (
    <div key={item.id} className="bg-white border-2 p-4 rounded-2xl">
      <img
        src={item.image}
        alt={item.title}
        className="w-[350px] rounded-2xl mb-5 border-2"
      />
      <h3 className=" font-medium mb-4">{item.title}</h3>
      <a href={item.url} target="_blank" rel="noreferrer">
        <span className="flex items-center gap-x-2 text-sm hover:font-medium hover:text-primary text-lightGrey">
          Demo
          <FaLongArrowAltRight className="hover:translate-x-2 duration-300" />
        </span>
      </a>
    </div>
  );
};

export default ProjectsItem;
