import AboutPage from "../../pages/About";
import Heading from "../Heading";

const AboutSection = () => {
  return (
    <div className="bg-faded px-6">
      <div className="pt-16 mx-auto">
        <Heading title={"About Me"} subtitle={"Who I am"} />
      </div>

      <AboutPage />
    </div>
  );
};

export default AboutSection;
