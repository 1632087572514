import { IoLogoWhatsapp } from "react-icons/io";
import { MdOutgoingMail } from "react-icons/md";
import { FaMapPin } from "react-icons/fa";
import ContactForm from "../components/ContactForm";

const ContactPage = () => {
  return (
    <section className="flex items-center md:h-[95vh] h-[145vh] overflow-x-hidden px-6 lg:px-0">
      <div className="mx-auto lg:w-[68%]">
        <div className="grid md:grid-cols-2 grid-cols-1 lg:gap-x-24 gap-x-12 gap-y-16">
          {/* left */}
          <div className="grid grid-cols-1">
            <h3 className="text-center font-medium text-lg mb-6">Talk to me</h3>

            <div className="flex flex-col justify-between gap-y-4">
              <div className="flex flex-col items-center bg-white border-2 p-4 rounded-xl">
                <IoLogoWhatsapp className=" text-3xl mb-1" />
                <h3 className="text-sm mb-2">WhatsApp</h3>
                <a
                  href="https://wa.me/+917046972528?text=Hi,%20need%20more%20information!"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="font-medium">+91 7046972528</span>
                </a>
              </div>

              <div className="flex flex-col items-center bg-white border-2 p-4 rounded-xl">
                <MdOutgoingMail className=" text-3xl mb-1" />
                <h3 className="text-sm mb-2">Email</h3>
                <a
                  href="mailto:vaghasiyaronak5968@gmail.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="font-medium">
                    vaghasiyaronak5968@gmail.com
                  </span>
                </a>
              </div>

              <div className="flex flex-col items-center bg-white border-2 p-4 rounded-xl">
                <FaMapPin className=" text-3xl mb-1" />
                <h3 className="text-sm mb-2">Address</h3>
                <a
                  href="https://goo.gl/maps/i42tPnJiCfvEyVqE9"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="font-medium">Surat, Gujarat-395006</span>
                </a>
              </div>
            </div>
          </div>
          {/* right */}
          <div>
            <h3 className="text-center font-medium text-lg mb-6">
              Write me your project
            </h3>

            <div>
              <ContactForm />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactPage;
