import React, { useState } from "react";
import { FaUserGraduate, FaCalendarDays } from "react-icons/fa6";
import { BsBagHeartFill } from "react-icons/bs";

const Education = () => {
  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  let classes =
    "flex gap-x-2 items-center cursor-pointer hover:font-medium text-lg";
  let activeClasses = classes + " font-semibold";

  let contentClasses = "hidden";
  let activeContentClasses = contentClasses + "block";
  return (
    <section className="flex items-center lg:h-[100vh] h-[80vh] overflow-x-hidden mx-6">
      <div className="lg:w-[68%] mx-auto">
        <div className="flex justify-center gap-x-12 mb-20">
          <div
            className={toggleState === 1 ? activeClasses : classes}
            onClick={() => toggleTab(1)}
          >
            <FaUserGraduate />
            <p>Education</p>
          </div>

          <div
            className={toggleState === 2 ? activeClasses : classes}
            onClick={() => toggleTab(2)}
          >
            <BsBagHeartFill className="text-lg" />
            <p>Experience</p>
          </div>
        </div>

        <div className="grid grid-cols-0.5 justify-center">
          <div
            className={
              toggleState === 1 ? activeContentClasses : contentClasses
            }
          >
            {/* first row */}
            <div className="grid grid-cols-2.1 gap-x-6">
              <div>
                <h3 className="font-medium">BTech IT</h3>
                <span className=" inline-block text-sm mb-4 text-lightGrey">
                  CGPIT, Uka Tarsadiya University
                </span>
                <div className="flex items-center text-sm gap-x-1 text-lightGrey">
                  <FaCalendarDays />
                  2021 - Present
                </div>
              </div>

              <div>
                {/* round */}
                <span className="inline-block w-[13px] h-[13px] rounded-full bg-primary"></span>
                {/* line */}
                <span className="block w-[1px] h-[80px] bg-primary transform translate-x-[6px]"></span>
              </div>
            </div>

            {/* second row */}
            <div className="grid grid-cols-2.1 gap-x-6">
              <div></div>

              <div>
                {/* round */}
                <span className="inline-block w-[13px] h-[13px] rounded-full bg-primary"></span>
                {/* line */}
                <span className="block w-[1px] h-[70px] bg-primary transform translate-x-[6px]"></span>
              </div>

              <div>
                <h3 className="font-medium">HSC</h3>
                <span className=" inline-block text-sm mb-4 text-lightGrey">
                  P.P.Savani Vidhyabhavan
                </span>
                <div className="flex items-center text-sm gap-x-1 text-lightGrey">
                  <FaCalendarDays />
                  2019 - 2021
                </div>
              </div>
            </div>

            {/* third row */}
            <div className="grid grid-cols-2.1 gap-x-6">
              <div>
                <h3 className="font-medium">SSC</h3>
                <span className=" inline-block text-sm mb-4 text-lightGrey">
                  Navchetan Vidhyabhavan
                </span>
                <div className="flex items-center text-sm gap-x-1 text-lightGrey">
                  <FaCalendarDays />
                  2018 - 2019
                </div>
              </div>

              <div>
                {/* round */}
                <span className="inline-block w-[13px] h-[13px] rounded-full bg-primary"></span>
                {/* line */}
                <span className="block w-[1px] h-[70px] bg-primary transform translate-x-[6px]"></span>
              </div>
            </div>
          </div>

          <div
            className={
              toggleState === 2 ? activeContentClasses : contentClasses
            }
          >
            {/* first row */}
            <div className="grid grid-cols-2.1 gap-x-6">
              <div></div>

              <div>
                {/* round */}
                <span className="inline-block w-[13px] h-[13px] rounded-full bg-primary"></span>
                {/* line */}
                <span className="block w-[1px] h-[70px] bg-primary transform translate-x-[6px]"></span>
              </div>

              <div>
                <h3 className="font-medium">Summer Intern</h3>
                <span className=" inline-block text-sm mb-4 text-lightGrey">
                  Weetech Solution Pvt Ltd
                </span>
                <div className="flex items-center text-sm gap-x-1 text-lightGrey">
                  <FaCalendarDays />
                  May 2024 - Jun 2024
                </div>
              </div>
            </div>

            {/* second row */}
            <div className="grid grid-cols-2.1 gap-x-6">
              <div>
                <h3 className="font-medium">Graphics Design</h3>
                <span className=" inline-block text-sm mb-4 text-lightGrey">
                  Visual Vortex
                </span>
                <div className="flex items-center text-sm gap-x-1 text-lightGrey">
                  <FaCalendarDays />
                  2023 - Present
                </div>
              </div>

              <div>
                {/* round */}
                <span className="inline-block w-[13px] h-[13px] rounded-full bg-primary"></span>
                {/* line */}
                <span className="block w-[1px] h-[70px] bg-primary transform translate-x-[6px]"></span>
              </div>
            </div>

            {/* third row */}
            <div className="grid grid-cols-2.1 gap-x-6">
              <div></div>

              <div>
                {/* round */}
                <span className="inline-block w-[13px] h-[13px] rounded-full bg-primary"></span>
                {/* line */}
                <span className="block w-[1px] h-[75px] bg-primary transform translate-x-[6px]"></span>
              </div>

              <div>
                <h3 className="font-medium">Web Development</h3>
                <span className=" inline-block text-sm mb-4 text-lightGrey">
                  Code Help
                </span>
                <div className="flex items-center text-sm gap-x-1 text-lightGrey">
                  <FaCalendarDays />
                  2021 - 2023
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Education;
