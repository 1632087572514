import { FaLaptopCode, FaPenNib } from "react-icons/fa";
import { HiOutlineSpeakerphone } from "react-icons/hi";

export const services = [
  {
    id: 1,
    title: "Web Development",
    icon: <FaLaptopCode className="block text-3xl mb-4" />,
    description:
      "I can build a responsive, fast and user-friendly website for you using modern web technologies.",
    features: [
      "Responsive Design",
      "Fast Loading",
      "User Friendly",
      "SEO Friendly",
      "Modern Web Technologies",
    ],
  },
  {
    id: 2,
    title: "Graphics Design",
    icon: <FaPenNib className="block text-3xl mb-4" />,
    description:
      "I can design a modern and creative logo for your brand or business.",
    features: [
      "Modern Design",
      "Creative",
      "High Quality",
      "Vector Format",
      "Unlimited Revisions",
    ],
  },
  {
    id: 3,
    title: "Digital Marketing",
    icon: <HiOutlineSpeakerphone className="block text-3xl mb-4" />,
    description:
      "I can help you to grow your business online using digital marketing strategies.",
    features: [
      "SEO",
      "Social Media Marketing",
      "Email Marketing",
      "Content Marketing",
      "PPC (Pay Per Click)",
    ],
  },
];
