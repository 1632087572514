import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import logo from "../assets/R.png";

import { FaGithub, FaLinkedinIn } from "react-icons/fa";
import { AiOutlineMenuFold } from "react-icons/ai";
import { IoClose } from "react-icons/io5";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const active = "font-semibold";

  const navLinks = [
    { name: "Home", path: "/", end: true },
    { name: "About", path: "/about" },
    { name: "Projects", path: "/projects" },
    { name: "Services", path: "/services" },
    { name: "Contact", path: "/contact" },
  ];

  function toggleSidebar() {
    setIsOpen(!isOpen);
  }

  const closeSidebar = () => {
    setIsOpen(false);
  };

  return (
    <div className="fixed z-[999] w-full bg-white">
      <header className="flex justify-between items-center my-4 max-w-[1120px] mx-auto px-6 lg:px-0">
        <h1 className="text-[26px] font-medium select-none z-[999]">
          <NavLink to="/">
            <img
              src={logo}
              alt="Ronak's Portfolio"
              width="270vh"
              height="270vh"
            />
          </NavLink>
        </h1>
        <nav>
          <div className="hidden lg:block">
            <ul className="flex justify-center items-center lg:text-lg gap-6">
              {navLinks.map((link) => (
                <li key={link.name}>
                  <NavLink
                    to={link.path}
                    className={({ isActive }) =>
                      isActive ? active : undefined
                    }
                    end={link.end}
                  >
                    {link.name}
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>

          {/* sidebar on mobile view */}

          {isOpen && (
            <div className="-z-10">
              <ul
                className="flex flex-col w-full h-full items-center gap-10 
              justify-center top-0 right-0 text-lg fixed shadow-2xl 
              backdrop-blur-lg transition-all"
              >
                {navLinks.map((link) => (
                  <li key={link.name} className="text-center w-full">
                    <NavLink
                      to={link.path}
                      className={({ isActive }) =>
                        isActive ? active : undefined
                      }
                      end={link.end}
                    >
                      <button onClick={closeSidebar}>{link.name}</button>
                    </NavLink>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </nav>

        {/* hemburger */}
        <div className="text-4xl flex lg:hidden z-[999]">
          <button onClick={toggleSidebar}>
            {isOpen ? <IoClose /> : <AiOutlineMenuFold />}
          </button>
        </div>

        {/* connection icon */}
        <div className="hidden lg:block">
          <div className="flex justify-center items-center gap-4 text-2xl">
            <a
              href="https://www.linkedin.com/in/ronakvaghasiya26"
              target="_blank"
              rel="noreferrer"
            >
              <FaLinkedinIn />
            </a>

            <a
              href="https://github.com/ronak-vaghasiya"
              target="_blank"
              rel="noreferrer"
            >
              <FaGithub />
            </a>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
