import { TbArrowBadgeRight } from "react-icons/tb";

const FrontendSkill = () => {
  return (
    <div className="flex flex-col gap-4 border-2 bg-faded py-12 px-8 rounded-2xl content-center">
      <h3 className="text-lg font-medium text-center mb-6">
        Frontend Technology
      </h3>

      <div className="flex justify-center gap-x-20">
        <div className="grid items-start gap-y-4">
          <div className="flex gap-x-1">
            <TbArrowBadgeRight className=" text-xl" />
            <div>
              <h3 className="font-medium leading-5">HTML</h3>
              <span className="text-xs">Advanced</span>
            </div>
          </div>

          <div className="flex gap-x-1">
            <TbArrowBadgeRight className=" text-xl" />
            <div>
              <h3 className="font-medium leading-5">CSS</h3>
              <span className="text-xs">Advanced</span>
            </div>
          </div>

          <div className="flex gap-x-1">
            <TbArrowBadgeRight className=" text-xl" />
            <div>
              <h3 className="font-medium leading-5">JavaScript</h3>
              <span className="text-xs">Intermediate</span>
            </div>
          </div>
        </div>

        <div className="grid items-start gap-y-4">
          <div className="flex gap-x-1">
            <TbArrowBadgeRight className=" text-xl" />
            <div>
              <h3 className="font-medium leading-5">React</h3>
              <span className="text-xs">Intermediate</span>
            </div>
          </div>

          <div className="flex gap-x-1">
            <TbArrowBadgeRight className=" text-xl" />
            <div>
              <h3 className="font-medium leading-5">Bootstrap</h3>
              <span className="text-xs">Intermediate</span>
            </div>
          </div>

          <div className="flex gap-x-1">
            <TbArrowBadgeRight className=" text-xl" />
            <div>
              <h3 className="font-medium leading-5">Git</h3>
              <span className="text-xs">Intermediate</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FrontendSkill;