import { TbArrowBadgeRight } from "react-icons/tb";

const BackendSkill = () => {
  return (
    <div className="flex flex-col gap-4 border-2 bg-faded py-12 px-12 md:px-6 rounded-2xl content-center">
      <h3 className="text-lg font-medium text-center mb-6">
        Backend Technology
      </h3>

      <div className="flex justify-center gap-x-20">
        <div className="grid items-start gap-y-4">
          <div className="flex gap-x-1">
            <TbArrowBadgeRight className=" text-xl" />
            <div>
              <h3 className="font-medium leading-5">Node</h3>
              <span className="text-xs">Beginner</span>
            </div>
          </div>

          <div className="flex gap-x-1">
            <TbArrowBadgeRight className=" text-xl" />
            <div>
              <h3 className="font-medium leading-5">PHP</h3>
              <span className="text-xs">Intermediate</span>
            </div>
          </div>

          <div className="flex gap-x-1">
            <TbArrowBadgeRight className=" text-xl" />
            <div>
              <h3 className="font-medium leading-5">Python</h3>
              <span className="text-xs">Intermediate</span>
            </div>
          </div>
        </div>

        <div className="grid items-start gap-y-4">
          <div className="flex gap-x-1">
            <TbArrowBadgeRight className=" text-xl" />
            <div>
              <h3 className="font-medium leading-5">MySQL</h3>
              <span className="text-xs">Beginner</span>
            </div>
          </div>

          <div className="flex gap-x-1">
            <TbArrowBadgeRight className=" text-xl" />
            <div>
              <h3 className="font-medium leading-5">Firebase</h3>
              <span className="text-xs">Intermediate</span>
            </div>
          </div>

          <div className="flex gap-x-1">
            <TbArrowBadgeRight className=" text-xl" />
            <div>
              <h3 className="font-medium leading-5">MongoDB</h3>
              <span className="text-xs">Intermediate</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BackendSkill;
